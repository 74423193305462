import React from "react";

const ProfitLoss = () => {
  return (
    <div>
      <h2>Profit/Loss</h2>
      <p>Profit/Loss details will be displayed here.</p>
    </div>
  );
};

export default ProfitLoss;
