import React from 'react'

export default function Footer() {
  return (
    <div>
    <h1 className="text-3xl font-bold bg-[#252525]  text-white w-[100%]">footer</h1>
  
</div>
  )
}
