import React, { useState } from "react";
import Card from "./Card";

export default function List() {
  
  return(

    <div>
      
      <Card/>
    
      
    </div>
    
   
    
  )
}
