// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-details {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
}

.report-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
  position: relative;
}

.report-header {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.logo-top-left {
  width: 50px;
  height: 50px;
}

.symbol-timestamp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.symbol {
  font-size: 18px;
  font-weight: bold;
}

.timestamp {
  font-size: 14px;
  color: #666;
}

.content {
  margin-top: 20px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.value {
  font-weight: normal;
}

.completed {
  color: green;
}

.pending {
  color: orange;
}

.underline {
  margin-top: 10px;
  margin-left: 30px;
  width: 80%;
  border: 1px solid #000;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TransactionDetails.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,UAAU;EACV,sBAAsB;AACxB","sourcesContent":[".transaction-details {\r\n  border: 1px solid #ddd;\r\n  padding: 20px;\r\n  margin-bottom: 20px;\r\n  background-color: #fff;\r\n}\r\n\r\n.report-title {\r\n  text-align: center;\r\n  font-size: 24px;\r\n  margin-bottom: 10px;\r\n  position: relative;\r\n}\r\n\r\n.report-header {\r\n  display: flex;\r\n  /* justify-content: space-between; */\r\n  align-items: center;\r\n}\r\n\r\n.logo-top-left {\r\n  width: 50px;\r\n  height: 50px;\r\n}\r\n\r\n.symbol-timestamp {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-end;\r\n}\r\n\r\n.symbol {\r\n  font-size: 18px;\r\n  font-weight: bold;\r\n}\r\n\r\n.timestamp {\r\n  font-size: 14px;\r\n  color: #666;\r\n}\r\n\r\n.content {\r\n  margin-top: 20px;\r\n}\r\n\r\n.label {\r\n  font-weight: bold;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.value {\r\n  font-weight: normal;\r\n}\r\n\r\n.completed {\r\n  color: green;\r\n}\r\n\r\n.pending {\r\n  color: orange;\r\n}\r\n\r\n.underline {\r\n  margin-top: 10px;\r\n  margin-left: 30px;\r\n  width: 80%;\r\n  border: 1px solid #000;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
