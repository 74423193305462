// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .dashboard-container {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color: #f8f9fa;
  padding: 1rem;
}

.sidebar h2 {
  margin-bottom: 1rem;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 0.5rem;
}

.sidebar li a {
  text-decoration: none;
  color: #007bff;
}

.sidebar li a:hover {
  text-decoration: underline;
}

.main-content {
  flex: 1;
  padding: 1rem;
} */
`, "",{"version":3,"sources":["webpack://./src/Admin/Dashboard.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAmCG","sourcesContent":["/* .dashboard-container {\r\n  display: flex;\r\n}\r\n\r\n.sidebar {\r\n  width: 200px;\r\n  background-color: #f8f9fa;\r\n  padding: 1rem;\r\n}\r\n\r\n.sidebar h2 {\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n.sidebar ul {\r\n  list-style: none;\r\n  padding: 0;\r\n}\r\n\r\n.sidebar li {\r\n  margin-bottom: 0.5rem;\r\n}\r\n\r\n.sidebar li a {\r\n  text-decoration: none;\r\n  color: #007bff;\r\n}\r\n\r\n.sidebar li a:hover {\r\n  text-decoration: underline;\r\n}\r\n\r\n.main-content {\r\n  flex: 1;\r\n  padding: 1rem;\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
