// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grad_text{
    background: linear-gradient(120deg,#5659f3 30%,#0ea7df 50%);
    background-clip:text;
    color:transparent; 
}
.grad_bg{
    background: linear-gradient(120deg,#5659f3 30%,#0e85df 90%);
    /* background-clip:text;
    color:transparent;  */
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;IAC3D,oBAAoB;IACpB,iBAAiB;AACrB;AACA;IACI,2DAA2D;IAC3D;yBACqB;AACzB","sourcesContent":[".grad_text{\n    background: linear-gradient(120deg,#5659f3 30%,#0ea7df 50%);\n    background-clip:text;\n    color:transparent; \n}\n.grad_bg{\n    background: linear-gradient(120deg,#5659f3 30%,#0e85df 90%);\n    /* background-clip:text;\n    color:transparent;  */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
