// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-wallet-info {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-wallet-info h1 {
  text-align: center;
  margin-bottom: 20px;
}

.admin-wallet-info .form-group {
  margin-bottom: 15px;
}

.admin-wallet-info .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.admin-wallet-info .form-group select,
.admin-wallet-info .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admin-wallet-info .form-group input[type="file"] {
  padding: 3px;
}

.admin-wallet-info button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-wallet-info button:hover {
  background-color: #45a049;
}

.admin-wallet-info p {
  text-align: center;
  color: #d32f2f;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Admin/AdminWalletInfo.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;;EAEE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".admin-wallet-info {\r\n  max-width: 500px;\r\n  margin: 0 auto;\r\n  padding: 20px;\r\n  background-color: #f4f4f4;\r\n  border-radius: 8px;\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.admin-wallet-info h1 {\r\n  text-align: center;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.admin-wallet-info .form-group {\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.admin-wallet-info .form-group label {\r\n  display: block;\r\n  margin-bottom: 5px;\r\n  font-weight: bold;\r\n}\r\n\r\n.admin-wallet-info .form-group select,\r\n.admin-wallet-info .form-group input {\r\n  width: 100%;\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n}\r\n\r\n.admin-wallet-info .form-group input[type=\"file\"] {\r\n  padding: 3px;\r\n}\r\n\r\n.admin-wallet-info button {\r\n  width: 100%;\r\n  padding: 10px;\r\n  background-color: #4caf50;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n}\r\n\r\n.admin-wallet-info button:hover {\r\n  background-color: #45a049;\r\n}\r\n\r\n.admin-wallet-info p {\r\n  text-align: center;\r\n  color: #d32f2f;\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
