// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-summary {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.summary-header {
  display: flex;
  align-items: center;
}

.summary-header img.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.transaction-details .label {
  font-weight: bold;
}

.transaction-details .value {
  font-weight: normal;
}

.status.completed {
  color: green;
}

.status.pending {
  color: orange;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TransactionSummary.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":[".transaction-summary {\r\n  border: 1px solid #ddd;\r\n  padding: 10px;\r\n  margin-bottom: 10px;\r\n  cursor: pointer;\r\n  /* display: flex; */\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.summary-header {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.summary-header img.logo {\r\n  width: 50px;\r\n  height: 50px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.transaction-details .label {\r\n  font-weight: bold;\r\n}\r\n\r\n.transaction-details .value {\r\n  font-weight: normal;\r\n}\r\n\r\n.status.completed {\r\n  color: green;\r\n}\r\n\r\n.status.pending {\r\n  color: orange;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
