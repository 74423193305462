// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Crypto Ticker Styling */
.crypto-ticker {
  width: 100%;
  overflow: hidden;
  background-color: #111;
  color: white;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 8px 0;
}

/* Scrolling Animation */
.ticker-content {
  display: flex;
  gap: 30px;
  animation: scroll-left 25s linear infinite;
  margin-bottom: 10px;
}

@keyframes scroll-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Crypto Item */
.ticker-item {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

/* Crypto Logo */
.crypto-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* Green for price increase, Red for price drop */
.ticker-item.up {
  color: #10b981; /* Green */
}

.ticker-item.down {
  color: #ef4444; /* Red */
}
`, "",{"version":3,"sources":["webpack://./src/Components/CryptoTicker.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,WAAW;EACX,gBAAgB;EAChB,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,cAAc;AAChB;;AAEA,wBAAwB;AACxB;EACE,aAAa;EACb,SAAS;EACT,0CAA0C;EAC1C,mBAAmB;AACrB;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;AACF;;AAEA,gBAAgB;AAChB;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA,gBAAgB;AAChB;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA,iDAAiD;AACjD;EACE,cAAc,EAAE,UAAU;AAC5B;;AAEA;EACE,cAAc,EAAE,QAAQ;AAC1B","sourcesContent":["/* Crypto Ticker Styling */\r\n.crypto-ticker {\r\n  width: 100%;\r\n  overflow: hidden;\r\n  background-color: #111;\r\n  color: white;\r\n  font-size: 14px;\r\n  font-weight: bold;\r\n  white-space: nowrap;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  z-index: 1000;\r\n  padding: 8px 0;\r\n}\r\n\r\n/* Scrolling Animation */\r\n.ticker-content {\r\n  display: flex;\r\n  gap: 30px;\r\n  animation: scroll-left 25s linear infinite;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n@keyframes scroll-left {\r\n  from {\r\n    transform: translateX(100%);\r\n  }\r\n  to {\r\n    transform: translateX(-100%);\r\n  }\r\n}\r\n\r\n/* Crypto Item */\r\n.ticker-item {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 0 20px;\r\n}\r\n\r\n/* Crypto Logo */\r\n.crypto-logo {\r\n  width: 20px;\r\n  height: 20px;\r\n  margin-right: 8px;\r\n}\r\n\r\n/* Green for price increase, Red for price drop */\r\n.ticker-item.up {\r\n  color: #10b981; /* Green */\r\n}\r\n\r\n.ticker-item.down {\r\n  color: #ef4444; /* Red */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
