// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-panel {
  padding: 20px;
}

.admin-panel h2 {
  margin-top: 0;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-content h3 {
  margin-top: 0;
}

/* .modal-content button {
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer;
} */

.admin-table th,
.admin-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.admin-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.admin-table td a {
  color: #0066cc;
  text-decoration: none;
}

.admin-table button {
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Admin/AdminPanel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;;;;;;GAMG;;AAEH;;EAEE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".admin-panel {\r\n  padding: 20px;\r\n}\r\n\r\n.admin-panel h2 {\r\n  margin-top: 0;\r\n}\r\n\r\n.admin-table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n.modal {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.modal-content {\r\n  background-color: white;\r\n  padding: 20px;\r\n  border-radius: 5px;\r\n  text-align: center;\r\n}\r\n\r\n.modal-content h3 {\r\n  margin-top: 0;\r\n}\r\n\r\n/* .modal-content button {\r\n  margin: 10px;\r\n  padding: 10px 20px;\r\n  border: 1px solid #000;\r\n  background-color: #fff;\r\n  cursor: pointer;\r\n} */\r\n\r\n.admin-table th,\r\n.admin-table td {\r\n  border: 1px solid #ddd;\r\n  padding: 8px;\r\n}\r\n\r\n.admin-table th {\r\n  background-color: #f2f2f2;\r\n  text-align: left;\r\n}\r\n\r\n.admin-table td a {\r\n  color: #0066cc;\r\n  text-decoration: none;\r\n}\r\n\r\n.admin-table button {\r\n  padding: 5px 10px;\r\n  font-size: 14px;\r\n  margin-right: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
